<template>
  <div class="ImgDecode">
    <div>
      <label for="formFileLg" class="form-label">Sélectionnez l'image contenant le CEV</label>
      <input class="form-control form-control-lg form-control-color " id="formFileLg" type="file" @change="decodeImg" accept="image/*">
    </div>
  </div>
</template>

<script>
import { BarcodeReader } from 'dynamsoft-javascript-barcode'
export default {
  name: 'ImgDecode',
  data() {
    return {
      pReader: null,
    }
  },
  methods: {
    async decodeImg(e) {
      try {
        const reader = await (this.pReader = this.pReader || BarcodeReader.createInstance());
        let results = await reader.decode(e.target.files[0]);
        for (let result of results) {
          alert(result.barcodeText);
        }
        if (!results.length) { alert('No barcode found'); }
      } catch (ex) {
        let errMsg;
        if (ex.message.includes("network connection error")) {
          errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
        } else {
          errMsg = ex.message || ex;
        }
        console.error(errMsg);
        alert(errMsg);
      }
      e.target.value = '';
    }
  },
  async beforeDestroy() {
    if (this.pReader) {
      (await this.pReader).destroyContext();
      console.log('ImgDecode Component Unmount');
    }
  },
}
</script>

<!-- <style scoped>
.ImgDecode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  border: 1px solid black
}
</style> -->