<template>
  <div>
    <!-- <div class="btn-group">
      <button :style="{ marginRight: '10px', backgroundColor: bShowScanner ? 'rgb(255,174,55)' : 'white' }"
        @click="showScanner">Video Decode</button>
      <button :style="{ backgroundColor: bShowImgDecode ? 'rgb(255,174,55)' : 'white' }" @click="showImgDecode">Image
        Decode</button>
    </div>
    <div class="container">
      <VideoDecode v-if="bShowScanner"></VideoDecode>
      <ImgDecode v-if="bShowImgDecode"></ImgDecode>
    </div> -->
    <b-row>
      <b-col md="12">
      </b-col>
      <b-col md="12">
        <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left">

          <!-- general tab -->
          <b-tab active>

            <!-- title -->
            <template #title>
              <feather-icon icon="UserIcon" size="18" class="mr-50" />
              <span class="font-weight-bold" @click="showImgDecode">Charger un fichier</span>
            </template>

            <b-card>
              <!-- form -->
              <b-form class="mt-2">
                <b-row>
                  <b-col sm="6">
                    <ImgDecode></ImgDecode>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-tab>
          <!--/ general tab -->

          <!-- Modifier mot de passe tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon icon="LockIcon" size="18" class="mr-50" />
              <span class="font-weight-bold" @click="showScanner">Scannez un CEV</span>
            </template>
            <b-card>
              <!-- form -->
              <b-form class="mt-2">
                <b-row>
                  <b-col sm="6">
                    <VideoDecode v-if="bShowScanner"></VideoDecode>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-tab>
          <!--/ Modifier mot de passe tab -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BSpinner,
  BTabs, BTab, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import "../../../dbr"; // import side effects. The license, engineResourcePath, so on.
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import VideoDecode from "./VideoDecode";
import ImgDecode from './ImgDecode.vue'
import { BarcodeScanner } from "dynamsoft-javascript-barcode";

export default {
  name: "HelloWorld",
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BInputGroupAppend,
    BInputGroup,
    VideoDecode, ImgDecode,
    BSpinner,
  },
  data() {
    return {
      bShowScanner: false,
      bShowImgDecode: false
    };
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      await BarcodeReader.loadWasm();
    } catch (ex) {
      let errMsg;
      if (ex.message.includes("network connection error")) {
        errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
      alert(errMsg);
    }
  },
  methods: {
    showScanner() {
      this.bShowScanner = true;
      this.bShowImgDecode = false;
    },
    showImgDecode() {
      this.bShowScanner = false;
      this.bShowImgDecode = true;
    },
    strcut(str, start, length) {
      return str.substring(start, start + length);
    },

    getHeader(data) {
      const dataArray = data.split('');
      const header = dataArray.slice(0, 22).join('');
      return header;
    },

    getMarker(data) {
      const dataArray = data.split('');
      const header = dataArray.slice(0, 2).join('');
      return header;
    },

    getCA(header) {
      return this.strcut(header, 4, 4);
    },

    getCertID(header) {
      return this.strcut(header, 8, 4);
    },

    getCreatedDate(header) {
      return this.strcut(header, 12, 4);
    },

    getSignatureDate(header) {
      return this.strcut(header, 16, 4);
    },

    getDocType(header) {
      const docTypeID = this.strcut(header, 20, 2);
      switch (docTypeID) {
        case '00':
          return 'Justificatif de domicile - Document émis spécifiquement pour servir de justificatif de domicile.';
        case '01':
          return 'Carte d’identité Biométrique';
        case '02':
          return 'Diplôme';
        case '03':
          return 'Titre foncier';
        default:
          return `Inconnu (ID: '${docTypeID}')`;
      }
    },

    async getCertURL(CA) {
      try {
        // Parse and process the XML data here as needed.
        return 'URL_FROM_XML'; // Replace with the actual URL obtained from XML.
      } catch (error) {
        console.log('Error fetching XML data:', error);
        return '';
      }
    },

    getMessage(allData) {
      const startIndex = this.headerSize;
      const endIndex = allData.indexOf('<US>', startIndex);
      if (endIndex !== -1) {
        this.message = allData.substring(startIndex, endIndex);
        return this.message;
      }
      return '';
    },

    getSignature(allData) {
      this.message = this.getMessage(allData);
      const startIndex = this.headerSize + this.message.length;
      this.signature = allData.substring(startIndex);
      return this.signature;
    }
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
